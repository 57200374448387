<template>
  <div id="app" class="page__inner" :class="isLoading ? 'loaded' : ''">
    <Preloader v-if="isLoading" />
    <header id="header" class="header">
      <div class="box">
        <div class="header__top">
          <div class="logo">
            <img src="./assets/images/logo.png" alt="logo" />
          </div>
          <div id="menu" class="menu"></div>
          <div class="lang">
            <select class="select" name="lang" @change="changeLanguage($event.target.value)">
              <option
                v-for="item of selectLang"
                :key="item.title"
                :value="item.value"
                :selected="item.selected"
                >{{ item.title }}
              </option>
            </select>
            <a class="lang__btn" :href="pathPublisher" target="_blank"
              >{{ $t('applyButton') }} <br />
              {{ $t('publisherButton') }}</a
            ><a class="lang__btn lang__btn--blue" :href="pathAdvertiser" target="_blank"
              >{{ $t('applyButton') }} <br />
              {{ $t('advertiserButton') }}</a
            >
          </div>
        </div>
      </div>
      <div class="nav">
        <div class="box">
          <nav id="nav" class="nav__list">
            <div id="close" class="nav__close"></div>
            <a class="nav__item scroll-to" href="#steps"> {{ $t('menuHowToUse') }}</a
            ><a class="nav__item scroll-to" href="#advantages">{{ $t('menuAdvantages') }}</a
            ><a class="nav__item scroll-to" href="#faq">{{ $t('menuFAQ') }}</a
            ><a class="nav__item scroll-to" href="#contact">{{ $t('menuContact') }}</a>
          </nav>
        </div>
      </div>
      <div class="box">
        <div class="header__promo">
          <h1 class="header__heading">
            <p>{{ $t('trafficTitle') }}</p>
            <p>{{ $t('monetizeTitle') }}</p>
          </h1>
          <img class="header__bg" src="./assets/images/header-bg.png" alt="header-bg" /><a
            class="button header__btn"
            :href="pathPublisher"
            target="_blank"
            >{{ $t('freeButton') }}</a
          >
        </div>
        <h2 class="heading">{{ $t('aboutQuestionTitle') }}?</h2>
        <p class="heading__about">{{ $t('aboutQuestionInfo') }}</p>
        <h2 class="heading">{{ $t('serviceQuestionTitle') }}?</h2>
        <div class="header__useful-wrap">
          <div class="header__useful-item">
            <img class="header__useful-icon" src="./assets/images/icon-useful-1.png" alt="icon-useful" />
            <p class="header__useful-service" :class="$i18n.locale !== 'ru' ? 'lang-padding__right' : ''">
              {{ $t('serviceQuestionInfo') }}
            </p>
          </div>
          <div class="header__useful-item">
            <img class="header__useful-icon" src="./assets/images/icon-useful-2.png" alt="icon-useful" />
            <p class="header__useful-monetize">
              {{ $t('monetizeQuestionInfo') }}
            </p>
          </div>
        </div>
      </div>
    </header>
    <main>
      <section id="steps" class="steps">
        <div class="box">
          <h2 class="heading heading--center">
            {{ $t('stepsTitle') }}
          </h2>
          <div class="steps__list">
            <div class="steps__item">
              <img class="steps__icon" src="./assets/images/icon-steps-1.png" alt="icon" />
              <p class="steps__info">{{ $t('stepsInfoOne') }}</p>
            </div>
            <div class="steps__item steps__item--2">
              <img class="steps__icon" src="./assets/images/icon-steps-2.png" alt="icon" />
              <p class="steps__info steps__info--2">{{ $t('stepsInfoTwo') }}</p>
            </div>
            <div class="steps__item steps__item--3">
              <img class="steps__icon" src="./assets/images/icon-steps-3.png" alt="icon" />
              <p class="steps__info">
                {{ $t('stepsInfoThree') }}
              </p>
            </div>
            <div class="steps__item steps__item--4">
              <img class="steps__icon" src="./assets/images/icon-steps-4.png" alt="icon" />
              <p class="steps__info">
                {{ $t('stepsInfoFour') }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="socials">
        <div class="box socials__inner">
          <div class="socials__content">
            <h2 class="heading socials__heading">
              {{ $t('registerTitle') }}
              <span class="socials__logo"><span>Bamboo</span>lead</span>
            </h2>
            <div class="socials__text">BAMBOOLEAD {{ $t('registerInfo') }}</div>
            <img class="socials__bg" src="./assets/images/socials-bg.png" alt="socials-bg" /><a
              class="button socials__btn"
              :href="pathPublisher"
              target="_blank"
              >{{ $t('takeOfferButton') }}</a
            >
            <div class="socials__text socials__text-contact">
              <p>{{ $t('takeOfferContact') }},</p>
              <p>{{ $t('takeOfferAnswer') }}</p>
            </div>
            <div class="socials__wrap">
              <!--              <a-->
              <!--                class="socials__z"-->
              <!--                href="https://zalo.me/4396172093095856033"-->
              <!--                title="https://zalo.me"-->
              <!--              >-->
              <!--              </a>-->
              <a class="socials__s" href="skype:live:.cid.a79de2938fc40fc5?chat" title="skype"></a
              ><a
                class="socials__fb"
                href="https://www.facebook.com/bamboolead.vietnam/"
                target="_blank"
                title="facebook"
              ></a>
            </div>
            <div class="socials__address">
              {{ $t('contactUsAddressTitle') }}:
              <span class="address__street">{{ $t('addressStreet') }}</span>
              <span class="address__district">{{ $t('addressDistrict') }}</span>
              <span class="address__city">{{ $t('addressCity') }}</span>
            </div>
          </div>
        </div>
      </section>
      <section id="advantages" class="advantages">
        <div class="box">
          <h2 class="heading heading--center">{{ $t('advantagesTitle') }}</h2>
          <div class="advantages__list">
            <div class="advantages__item">
              <div class="advantages__item-icon">
                <img src="./assets/images/icon-advantages-1.png" alt="icon" />
              </div>
              <div class="advantages__item-inner">
                <div class="advantages__item-title">
                  {{ $t('advantagesTrackingTitle') }}
                </div>
                <div class="advantages__item-text">
                  {{ $t('advantagesTrackingText') }}
                </div>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantages__item-icon">
                <img src="./assets/images/icon-advantages-2.png" alt="icon" />
              </div>
              <div class="advantages__item-inner">
                <div class="advantages__item-title">
                  {{ $t('advantagesAbilityTitle') }}
                </div>
                <div class="advantages__item-text">
                  {{ $t('advantagesAbilityText') }}
                </div>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantages__item-icon">
                <img src="./assets/images/icon-advantages-3.png" alt="icon" />
              </div>
              <div class="advantages__item-inner">
                <div class="advantages__item-title">
                  {{ $t('advantagesTransferTitle') }}
                </div>
                <div class="advantages__item-text">
                  {{ $t('advantagesTransferText') }}
                </div>
              </div>
            </div>
            <div class="advantages__item">
              <div class="advantages__item-icon">
                <img src="./assets/images/icon-advantages-4.png" alt="icon" />
              </div>
              <div class="advantages__item-inner">
                <div class="advantages__item-title">
                  {{ $t('advantagesSupportTitle') }}
                </div>
                <div class="advantages__item-text">
                  {{ $t('advantagesSupportText') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" class="faq">
        <img class="faq__bg" src="./assets/images/faq-bg.png" />
        <div class="box">
          <h2 class="heading heading--center">{{ $t('faqTitle') }}</h2>
          <dl>
            <dt>{{ $t('faqWorkQuestion') }}?</dt>
            <dd>{{ $t('faqWorkResponse') }}</dd>
            <dt>{{ $t('faqGetQuestion') }}?</dt>
            <dd>{{ $t('faqGetResponse') }}</dd>
            <dt>{{ $t('faqPayQuestion') }}?</dt>
            <dd>{{ $t('faqPayResponse') }}</dd>
          </dl>
        </div>
      </section>
      <section id="contact" class="contact">
        <div class="box">
          <h2 class="heading heading--center">{{ $t('contactTitle') }}</h2>
          <div class="contact__inner">
            <div class="contact__content">
              <a class="contact__link" href="mailto:info@bamboolead.com" title="mailto">info@bamboolead.com</a
              ><a class="button contact__btn" :href="pathPublisher" target="_blank">{{
                $t('takeOfferButton')
              }}</a>
              <h3 class="contact__h3">{{ $t('contactUsTitle') }}</h3>
              <div class="socials__wrap contact__socials">
                <!--                <a-->
                <!--                  class="socials__z"-->
                <!--                  href="https://zalo.me/4396172093095856033"-->
                <!--                  title="https://zalo.me"-->
                <!--                >-->
                <!--                </a>-->
                <a class="socials__s" href="skype:live:.cid.a79de2938fc40fc5?chat" title="skype"></a>
                <a
                  class="socials__fb"
                  href="https://www.facebook.com/bamboolead.vietnam/"
                  target="_blank"
                  title="facebook"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <img class="contact__bg" src="./assets/images/contact-bg.png" alt="contact-bg" />
      </section>
    </main>
    <footer class="footer">
      <div class="box">
        <div class="footer__inner-top">
          <div class="logo footer__logo">
            <img src="./assets/images/logo.png" alt="logo" />
          </div>
          <div class="footer__desc">
            <div>{{ $t('footerSiteTitle') }}</div>
            <div>{{ $t('footerSiteDesc') }}</div>
            <div class="socials__address footer__address">
              {{ $t('contactUsAddressTitle') }}:
              <span class="address__street">{{ $t('addressStreet') }}</span>
              <span class="address__district">{{ $t('addressDistrict') }}</span>
              <span class="address__city">{{ $t('addressCity') }}</span>
            </div>
            <div>{{ $t('footerTaxCode') }}</div>
          </div>
        </div>
        <div class="footer__inner-bottom">
          <div class="nav footer__nav">
            <nav class="nav__list">
              <a class="nav__item scroll-to" href="#header">{{ $t('menuHome') }}</a
              ><a class="nav__item scroll-to" href="#steps">{{ $t('menuHowToUse') }}</a
              ><a class="nav__item scroll-to" href="#advantages">{{ $t('menuAdvantages') }}</a
              ><a class="nav__item scroll-to" href="#faq">{{ $t('menuFAQ') }}</a
              ><a class="nav__item scroll-to" href="#contact">{{ $t('menuContact') }}</a>
            </nav>
          </div>
          <div>
            <a :href="pathTerms" target="_blank" class="footer__terms-link">{{ $t('termTextLink') }}</a>
            <span class="footer__year">2021 ©</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Preloader from './components/preloader/Preloader'

export default {
  name: 'App',
  components: {Preloader},
  data: () => ({
    isLoading: true,
    selectLang: [
      {
        title: 'ENG',
        value: 'en',
        selected: true
      },
      {
        title: 'VN',
        value: 'vn',
        selected: false
      },
      {
        title: 'RU',
        value: 'ru',
        selected: false
      }
    ],
    lang: localStorage.getItem('SYSTEM_LANGUAGE_BAMBOOLEAD'),
    pathPublisher: process.env.VUE_APP_API_URL + '/portal/publisher/registration',
    pathAdvertiser: process.env.VUE_APP_API_URL + '/portal/advertiser/registration'
  }),
  computed: {
    pathTerms() {
      return process.env.VUE_APP_API_URL + `/portal/terms?lang=${this.$i18n.locale}`
    }
  },
  methods: {
    changeLanguage(val) {
      this.isLoading = true
      this.$i18n.locale = val
      localStorage.setItem('SYSTEM_LANGUAGE_BAMBOOLEAD', val)
    }
  },
  mounted() {
    if (this.lang) {
      this.selectLang = this.selectLang.map(item => {
        item.selected = item.value === this.lang
        return item
      })
    }
  },
  watch: {
    isLoading: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.isLoading = false
          }, 1000)
        }
      }
    }
  }
}
</script>
