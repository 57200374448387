'use strict'
function _createForOfIteratorHelper(t, e) {
  let r
  if ('undefined' == typeof Symbol || null == t[Symbol.iterator]) {
    if (Array.isArray(t) || (r = _unsupportedIterableToArray(t)) || (e && t && 'number' == typeof t.length)) {
      r && (t = r)
      let n = 0,
        o = function() {}
      return {
        s: o,
        n: function() {
          return n >= t.length ? {done: !0} : {done: !1, value: t[n++]}
        },
        e: function(t) {
          throw t
        },
        f: o
      }
    }
    throw new TypeError(
      'Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.'
    )
  }
  // eslint-disable-next-line no-unused-vars
  let a,
    i = !0,
    u = !1
  return {
    s: function() {
      r = t[Symbol.iterator]()
    },
    n: function() {
      let t = r.next()
      return (i = t.done), t
    },
    e: function(t) {
      ;(u = !0), (a = t)
    },
    f: function() {
      try {
        i || null == r.return || r.return()
      } finally {
        if (u) console.log(u)
      }
    }
  }
}
function _unsupportedIterableToArray(t, e) {
  if (t) {
    if ('string' == typeof t) return _arrayLikeToArray(t, e)
    let r = Object.prototype.toString.call(t).slice(8, -1)
    return (
      'Object' === r && t.constructor && (r = t.constructor.name),
      'Map' === r || 'Set' === r
        ? Array.from(t)
        : 'Arguments' === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)
        ? _arrayLikeToArray(t, e)
        : void 0
    )
  }
}
function _arrayLikeToArray(t, e) {
  ;(null == e || e > t.length) && (e = t.length)
  for (var r = 0, n = new Array(e); r < e; r++) n[r] = t[r]
  return n
}
function init() {
  toggleMenu()
  linkTo()
}
function toggleMenu() {
  let t = document.getElementById('menu'),
    e = document.getElementById('close'),
    r = document.getElementById('nav')
  t.addEventListener('click', function() {
    r.classList.add('show')
  })
  e.addEventListener('click', function() {
    r.classList.remove('show')
  })
}
function linkTo() {
  let t,
    e = _createForOfIteratorHelper(document.querySelectorAll('a.scroll-to'))
  try {
    for (e.s(); !(t = e.n()).done; )
      !(function() {
        let r = t.value
        r.addEventListener('click', function(t) {
          let navEl = document.getElementById('nav')
          if (navEl.classList.contains('show')) {
            navEl.classList.remove('show')
          }
          t.preventDefault()
          let e = r.getAttribute('href').substr(1)
          document.getElementById(e).scrollIntoView({behavior: 'smooth', block: 'start'})
        })
      })()
  } catch (t) {
    e.e(t)
  } finally {
    e.f()
  }
}
document.addEventListener('DOMContentLoaded', function() {
  init()
})
