import Vue from 'vue'
import VueI18n from 'vue-i18n'

import english from './english.json'
import vietnam from './vietnam.json'
import russian from './russian.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale:
    localStorage.getItem('SYSTEM_LANGUAGE_BAMBOOLEAD') !== null
      ? localStorage.getItem('SYSTEM_LANGUAGE_BAMBOOLEAD')
      : 'en', // set default locale
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {
    en: english,
    vn: vietnam,
    ru: russian
  }
})
